<template>
  <div class="mine-page">
    <div class="user-info">
      <div class="user-detail" @click="toBVue('/mineInfoEdit')">
        <img class="user-phone" v-if="userinfo.avatarUrl == ''" src="../../assets/images/index/home_21.jpg" alt="">
        <img class="user-phone" v-if="userinfo.avatarUrl" :src="userinfo.avatarUrl" alt="">
        <div>
          <p class="user-name">{{userinfo.username}}</p>
          <van-button class="btn-modify" type="primary">修改</van-button>
        </div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="user-likes-item" @click="toBVue('/mineCollect')">
      <p>
        <i class="icon-shoucang"></i>收藏夹
      </p>
      <van-icon name="arrow"/>
    </div>
    <div class="user-likes-item" @click="toBVue('/mineLive')">
      <p>
        <i class="icon-live"></i>直播订阅
      </p>
      <van-icon name="arrow"/>
    </div>
  </div>
</template>

<script>
// import cookies from "@/libs/util.cookies";
import {Dialog} from "vant";
export default {
  name: 'Mine',
  data(){
    return {
      userinfo: {
        'avatarUrl': '',
        'username': '框众蒲公英',
        'id': 0
      }
    }
  },
  async mounted() {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    console.log('用户信息my', userinfo)
    if (!userinfo) {
      Dialog({ message: '未授权用户信息' });
    } else {
      this.userinfo = userinfo
    }
  },
  created() {
    
  },
  methods:{
    toBVue(toPath) {
      if (this.userinfo.id == 0) {
        Dialog({ message: '未授权用户信息' });
      } else {
        this.$router.push({path: toPath})
      }
    }
  }
}
</script>

<style lang='less' scoped>
  .mine-page{
    margin:0 16px;
  }
  .user-info{
    display:flex;
    align-items: center;
    justify-content: space-between;
    padding:16px 0;
    border-bottom:1px solid rgba(0,0,0,0.1);
    .user-phone{
      width:80px;
      height:80px;
      border-radius:40px;
      margin-right:20px;
    }
    .user-detail{
      display:flex;
      align-items: center;
      .user-name{
        font-size:18px;
        color:#333;
      }
      .btn-modify{
        width:48px;
        height:28px;
        border-radius:28px;
        font-size:14px;
        padding:0;
        text-align: center;
        margin-top:10px;
      }
    }
  }

  .user-likes-item{
    display:flex;
    align-items:center;
    justify-content: space-between;
    padding:28px 0;
    font-size:16px;
    border-bottom:1px solid rgba(0,0,0,0.1);
    p{
      display:flex;
      align-items: center;
    }
    .icon-shoucang,.icon-live{
      display:block;
      width:20px;
      height:20px;
      background:url(../../assets/images/mine/icon-sc.png) no-repeat;
      background-size:100% auto;
      margin-right:10px;
    }
    .icon-live{
      width:20px;
      height:15px;
      background-image:url(../../assets/images/mine/icon-zb.png);
    }
  }
</style>
